<template>
  <div id="container" class="svg-container">
    <svg :id="'lineskill' + id"></svg>
    <!--div :id="'barcat_tt' + category.id" class="tooltip">
      <p class="hidden">
        <strong><span id="value"></span></strong>
      </p>
    </div-->
  </div>
</template>

<script>
import * as d3 from "d3";
import moment from "moment";

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    data: {
      type: Array,
      required: true,
    },
    games: {
      type: Array,
      required: true,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const margin = { top: 20, right: 30, bottom: 50, left: 30 };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      var parseTime = d3.timeParse("%Y-%m-%d");

      const comp = this;

      this.data.forEach(function (d) {
        d.labelDate = comp.formatDate(d.date);
        d.date = parseTime(d.date);
      });

      const x = d3
        .scaleTime()
        .domain(
          d3.extent(this.data, function (d) {
            return d.date;
          })
        )
        .range([0, width]);

      const max = d3.max(this.data, function (d) {
        return d.value;
      });
      const min = d3.min(this.data, function (d) {
        return d.value;
      });

      const y = d3.scaleLinear().domain([min, max]).range([height, 0]);

      const line = d3
        .line()
        .x(function (d) {
          return x(d.date);
        })
        .y(function (d) {
          return y(d.value);
        })
        .curve(d3.curveMonotoneX);

      const svg = d3
        .select("#lineskill" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        )
        .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // add the x Axis
      this.chart
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickFormat(d3.timeFormat("%d/%m")))
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("font-size", "0.5rem")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");

      // add the y Axis
      this.chart
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(y).tickSize(-width).ticks(5));
      //.tickFormat(""));

      this.chart
        .append("path")
        .datum(this.data)
        .attr("class", "lineskill")
        .attr("d", line);

      const tooltip = d3.select("#tooltipline");
      const games = this.games;

      this.chart
        .selectAll(".dot")
        .data(this.data)
        .enter()
        .append("circle")
        .attr("class", "dot")
        .attr("cx", function (d) {
          return x(d.date);
        })
        .attr("cy", function (d) {
          return y(d.value);
        })
        .attr("r", 5)
        .on("mouseover", function (d) {
          var partita = comp.getPartita(d.game_id);
          if (partita) {
            tooltip.select("#leagueline").text(partita.league.name);
            tooltip
              .select("#imghomeline")
              .attr(
                "src",
                partita.team_home.squadra
                  ? partita.team_home.squadra.logo_url
                  : "/assets/images/team.png"
              );
            tooltip
              .select("#imgawayline")
              .attr(
                "src",
                partita.team_away.squadra
                  ? partita.team_away.squadra.logo_url
                  : "/assets/images/team.png"
              );

            tooltip
              .select("#gameline")
              .text(
                (partita.team_home ? partita.team_home.name : "") +
                  " - " +
                  (partita.team_away ? partita.team_away.name : "")
              );

            tooltip.select("#dateline").text(comp.formatDate(partita.date));
            tooltip.select("#scoreline").text(partita.score);

            tooltip.select("#valueline").text(d.value);

            tooltip
              .transition()
              .duration(200)
              .style("left", event.pageX - 70 + "px")
              .style("top", event.pageY - 50 + "px")
              .style("opacity", 0.9);
          }
        })
        .on("mouseout", function () {
          tooltip.transition().duration(500).style("opacity", 0);
        });
    },

    getPartita(id) {
      for (var i in this.games) {
        for (var j in this.games[i].partite) {
          var partita = this.games[i].partite[j];
          if (partita.id == id) {
            return partita;
          }
        }
      }
      return null;
    },

    formatDate: function (date) {
      var m = moment.utc(date).local();
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}

.grid {
  font: 12px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}

/* 13. Basic Styling with CSS */

/* Style the lines by removing the fill and applying a stroke */
.lineskill {
  fill: none;
  stroke: #28a745;
  stroke-width: 3;
}

/* Style the dots by assigning a fill and stroke */
.dot {
  fill: #28a745;
  stroke: #fff;
}
</style>
