<template>
  <div id="containerplot">
    <svg :id="'dotplotskills' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 800,
    },
    top: {
      type: Number,
      default: 10,
    },
    right: {
      type: Number,
      default: 40,
    },
    bottom: {
      type: Number,
      default: 10,
    },
    left: {
      type: Number,
      default: 90,
    },
    data: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const margin = {
        top: this.top,
        right: this.right,
        bottom: this.bottom,
        left: this.left,
      };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      const x = d3.scaleLinear().domain([0, 10]).range([0, width]);
      const y = d3
        .scaleBand()
        .range([0, height])
        .domain(
          this.data.map(function (d) {
            return d.label;
          })
        )
        .padding(0.5);

      const svg = d3
        .select("#dotplotskills" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        );

      /*    .classed("svg-content", true);*/

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      this.chart
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(y).tickSize(0))
        .selectAll("text")
        .attr("font-size", "0.2rem");

      if (this.title) {
        this.chart
          .append("text")
          .attr("class", "title")
          .attr("x", width / 2)
          .attr("y", 0 - margin.top / 2)
          .attr("text-anchor", "middle")
          .attr("fill", "#FFFFFF")
          .attr("font-size", "0.3rem")
          .text(this.title);
      }

      this.chart
        .selectAll(".line")
        .data(this.data)
        .enter()
        .append("line")
        .attr("class", "line")
        .attr("x1", 0)
        .attr("x2", width)
        .attr("y1", function (d) {
          return y(d.label) + y.bandwidth() / 2;
        })
        .attr("y2", function (d) {
          return y(d.label) + y.bandwidth() / 2;
        })
        .attr("stroke", "grey")
        .attr("stroke-width", "0.5px");

      this.chart
        .selectAll(".lineMedia")
        .data(this.data)
        .enter()
        .append("line")
        .attr("fill", "#FFFFFF")
        .attr("class", "lineMedia")
        .attr("x1", function (d) {
          return x(d.media_score);
        })
        .attr("x2", function (d) {
          return x(d.media_score);
        })
        .attr("y1", function (d) {
          return y(d.label) - y.bandwidth() / 2 + 1;
        })
        .attr("y2", function (d) {
          return y(d.label) + y.bandwidth() + 1;
        })
        .attr("stroke-width", "0.5px")
        .attr("stroke", "white")
        .attr("stroke-dasharray", "0.5,0.5");

      const tooltip = d3.select("#tooltipdotplot");

      for (var i in this.data) {
        var scores = this.data[i].scores;
        var label = this.data[i].label;
        var id = this.data[i].id;

        this.chart
          .selectAll(".plot" + id)
          .data(scores)
          .enter()
          .append("circle")
          .attr("class", "plot" + id)
          .attr("cx", function (d) {
            return x(d[0]);
          })
          .attr("cy", function () {
            return y(label) + y.bandwidth() / 2;
          })
          .attr("r", "1")
          .attr("cursor", "pointer")
          .style("fill", "#CCCCCC")
          .on("mouseover", function (d) {
            tooltip
              .transition()
              .duration(200)
              .style("left", event.pageX - 70 + "px")
              .style("top", event.pageY - 50 + "px")
              .style("opacity", 0.9)
              .select("#labeldotplot")
              .text(d[1]);

            tooltip.select("#imgdotplot").attr("src", d[2]);
          })
          .on("mouseout", function () {
            tooltip.transition().duration(500).style("opacity", 0);
          })
          .on("click", function (d) {
            window.open(d[3]);
          });
      }

      this.chart
        .selectAll(".plot_coach")
        .data(this.data)
        .enter()
        .append("circle")
        .attr("class", "plot_coach" + id)
        .attr("cx", function (d) {
          return x(d.score);
        })
        .attr("cy", function (d) {
          return y(d.label) + y.bandwidth() / 2;
        })
        .attr("r", "2")
        .style("fill", function (d) {
          return d.color;
        });

      this.chart
        .selectAll(".value_coach")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.2rem")
        .attr("class", "value_coach")
        .attr("text-anchor", "middle")
        .attr("y", function (d) {
          return y(d.label) + y.bandwidth() / 2 - 3;
        })
        .attr("x", function (d) {
          return x(d.score);
        })
        .text(function (d) {
          return d.value;
        });

      this.chart
        .selectAll(".rank_coach")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.25rem")
        .attr("class", "rank_coach")
        .attr("text-anchor", "middle")
        .attr("y", function (d) {
          return y(d.label) + y.bandwidth() / 2 + 1;
        })
        .attr("x", function (d) {
          return width + 10;
        })
        .text(function (d) {
          return d.rank;
        });

      this.chart
        .selectAll(".rate_coach")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", function (d) {
          return d.rate > 0 ? "green" : d.rate < 0 ? "red" : "white";
        })
        .attr("font-size", "0.25rem")
        .attr("font-weight", "bold")
        .attr("class", "rate_coach")
        .attr("text-anchor", "middle")
        .attr("y", function (d) {
          return y(d.label) + y.bandwidth() / 2 + 1;
        })
        .attr("x", function (d) {
          return width + 24.5;
        })
        .text(function (d) {
          return d.rate;
        });

      if (this.data.length > 0 && this.data[0].volatility) {
        this.chart
          .selectAll(".volatility")
          .data(this.data)
          .enter()
          .append("text")
          .attr("fill", "#FFFFFF")
          /*  .attr("fill", function (d) {
          var color = '#FFFFFF';
          if(d.volatility >= 0 && d.volatility <= 10) {
            color = "#00B386";
          } else if(d.volatility > 10 && d.volatility <= 20) {
            color = "#2EC85B";
          } else if(d.volatility > 20 && d.volatility <= 30) {
            color = "#7BDE3C";
          } else if(d.volatility > 30 && d.volatility <= 40) {
            color = "#B8F100";
          } else if(d.volatility > 40 && d.volatility <= 50) {
            color = "#E9F700";
          } else if(d.volatility > 50 && d.volatility <= 60) {
            color = "#FFF300";
          } else if(d.volatility > 60 && d.volatility <= 70) {
            color = "#FFDB00";
          } else if(d.volatility > 70 && d.volatility <= 80) {
            color = "#FFB800";
          } else if(d.volatility > 80 && d.volatility <= 90) {
            color = "#FF8900";
          } else if(d.volatility > 90 && d.volatility <= 100) {
            color = "#FF0059";
          }
          return color;
        }) */
          .attr("font-size", "0.19rem")
          .attr("class", "volatility")
          .attr("text-anchor", "middle")
          .attr("y", function (d) {
            return y(d.label) + y.bandwidth() / 2 + 0.7;
          })
          .attr("x", function (d) {
            return width + 35;
          })
          .text(function (d) {
            return d.volatility + "%";
          });
      }
    },
  },
};
</script>
<style>
.grid {
  font: 8px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}
</style>
