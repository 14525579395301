<template>
  <div id="container" class="svg-container" style="padding-bottom: 42%">
    <svg :id="'barskill' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 150,
    },
    data: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    media: {
      type: Number,
      required: true,
    },
    hideX: {
      type: Boolean,
      defalut: false,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const margin = { top: 10, right: 30, bottom: 55, left: 50 };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      const x = d3
        .scaleLinear()
        .domain([
          this.min < 0 ? this.min : 0,
          this.data[1]
            ? Math.max(this.max, this.data[0].score, this.data[1].score)
            : this.data[0]
            ? Math.max(this.max, this.data[0].score)
            : this.max,
          //this.data[1].score > this.max ? this.data[1].score : this.max,
        ])
        .range([0, width]);
      const y = d3
        .scaleBand()
        .range([0, height])
        .domain(
          this.data.map(function (d) {
            return d.label;
          })
        )
        .padding(0.5);

      const svg = d3
        .select("#barskill" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        )
        .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // add the x Axis
      const chart = this.chart
        .append("g")
        .attr("transform", "translate(0," + height + ")");

      if (!this.hideX) {
        chart.call(d3.axisBottom(x));
      }

      chart
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("font-size", "0.65rem")
        .attr("dx", "-.8em")
        .attr("dy", ".15em")
        .attr("transform", "rotate(-45)");

      // add the y Axis
      this.chart
        .append("g")
        .attr("class", "grid")
        .call(d3.axisLeft(y).tickSize(0));

      // append the rectangles for the bar chart

      this.chart
        .selectAll(".bar")
        .data(this.data)
        .enter()
        .append("rect")
        .attr("fill", function (d) {
          return d.color;
        })
        .attr("x", x(0))
        .attr("height", y.bandwidth())
        .attr("y", function (d) {
          return y(d.label);
        })
        .attr("width", 0)
        .transition()
        .duration(750)
        .delay(function (d, i) {
          return i * 150;
        })
        .attr("x", (d) => {
          return x(0);
        })
        .attr("width", (d) => {
          return x(d.score > 0 ? d.score : 0);
        });

      this.chart
        .selectAll(".bar")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.65rem")
        .attr("class", "label")
        .attr("y", function (d) {
          return y(d.label) + y.bandwidth() / 2 + 4;
        })
        .attr("x", function (d) {
          return x(d.score) + 3;
        })
        .text(function (d) {
          return d.score != "" &&
            !isNaN(d.score) &&
            Math.round(d.score) != d.score
            ? Math.round(d.score * 100) / 100
            : d.score;
        });

      const lineMedia = this.media;

      this.chart
        .append("line")
        .attr("x1", function () {
          return x(lineMedia);
        })
        .attr("x2", function () {
          return x(lineMedia);
        })
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke-width", 1)
        .attr("stroke", "white")
        .attr("stroke-dasharray", "5,5");

      this.chart
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.65rem")
        .attr("class", "barsEndlineText")
        .attr("text-anchor", "middle")
        .attr("x", function () {
          return x(lineMedia);
        })
        .attr("y", -2)
        .text(lineMedia);
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}

.grid {
  font: 10px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}
</style>
