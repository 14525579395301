<template>
  <!--div id="container" class="svg-container"-->
  <div id="container">
    <svg :id="'barskill' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      default: 100,
    },
    height: {
      type: Number,
      default: 200,
    },
    data: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      default: null,
    },
    min: {
      type: Number,
      default: null,
    },
    media: {
      type: Number,
      default: null,
    },
    hideY: {
      type: Boolean,
      defalut: false,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const margin = { top: 10, right: 15, bottom: 20, left: 30 };
      const width = this.width - margin.left - margin.right;
      const height = this.height - margin.top - margin.bottom;

      const x = d3
        .scaleBand()
        .range([0, width])
        .domain(
          this.data.map(function (d) {
            return d.label;
          })
        )
        .padding(0.5);

      const y = d3
        .scaleLinear()
        .domain([
          0,
          this.data[1]
            ? Math.max(
                this.max,
                this.data[0] ? this.data[0].score : 0,
                this.data[1].score
              )
            : Math.max(this.max, this.data[0] ? this.data[0].score : 0),
        ])
        .range([height, 0]);

      const svg = d3
        .select("#barskill" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr(
          "viewBox",
          `0 0 ${width + margin.left + margin.right} ${
            height + margin.top + margin.bottom
          }`
        );
      /*   .classed("svg-content", true); */

      this.chart = svg
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // add the x Axis
      this.chart
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .attr("class", "grid")
        .call(d3.axisBottom(x).tickSize(0))
        .selectAll("text")
        .attr("font-size", "0.45rem");
      //   .attr("transform", "translate(-10,0)rotate(-45)")
      //   .style("text-anchor", "end");

      // add the y Axis
      const chart = this.chart.append("g");
      if (!this.hideY) {
        chart.call(d3.axisLeft(y));
      }
      chart.selectAll("text").attr("font-size", "0.5rem");

      // append the rectangles for the bar chart

      this.chart
        .selectAll(".bar")
        .data(this.data)
        .enter()
        .append("rect")
        .attr("fill", function (d) {
          return d.color;
        })
        .attr("x", function (d) {
          return x(d.label);
        })
        .attr("y", function (d) {
          return y(0);
        })
        .attr("height", function (d) {
          return height - y(0);
        })
        .attr("width", x.bandwidth())
        //    .attr("height", 0)
        .transition()
        .duration(750)
        .delay(function (d, i) {
          return i * 150;
        })
        .attr("y", function (d) {
          return y(d.score > 0 ? d.score : 0);
        })
        .attr("height", function (d) {
          return height - y(d.score > 0 ? d.score : 0);
        });

      this.chart
        .selectAll(".bar")
        .data(this.data)
        .enter()
        .append("text")
        .attr("fill", "#FFFFFF")
        .attr("font-size", "0.5rem")
        .attr("class", "label")
        .attr("x", function (d) {
          return x(d.label);
        })
        .attr("y", function (d) {
          return y(d.score) - 2;
        })
        .text(function (d) {
          return d.score != "" &&
            !isNaN(d.score) &&
            Math.round(d.score) != d.score
            ? Math.round(d.score * 100) / 100
            : d.score;
        });

      if (!isNaN(this.media)) {
        const lineMedia = this.media;

        this.chart
          .append("line")
          .attr("y1", function () {
            return y(lineMedia);
          })
          .attr("y2", function () {
            return y(lineMedia);
          })
          .attr("x1", 0)
          .attr("x2", width - 5)
          .attr("stroke-width", 1)
          .attr("stroke", "white")
          .attr("stroke-dasharray", "3,3");

        this.chart
          .append("text")
          .attr("fill", "#FFFFFF")
          .attr("font-size", "0.5rem")
          .attr("class", "barsEndlineText")
          .attr("text-anchor", "middle")
          .attr("y", function () {
            return y(lineMedia) + 2;
          })
          .attr("x", width + 5)
          .text(lineMedia);
      }
    },
  },
};
</script>
<style>
.tooltip {
  position: absolute;
  z-index: 999;
  width: 150px;
  height: auto;
  padding: 5px 10px 5px 10px;
  background: rgba(255, 255, 255, 0.8);
  color: #000;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.4);
  -mox-box-shadow: 4px 4px 4px 10px rgba(0, 0, 0, 0.4);
  box-shadow: 4px 4px 10px rbga(0, 0, 0, 0.4);
  pointer-events: none;
}
.tooltip.hidden {
  opacity: 0;
}
.tooltip p {
  margin: 0;
  font-size: 10px;
  line-height: 20px;
}

.grid {
  font: 10px sans-serif;
}
.grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}
.grid path {
  stroke-width: 0;
}
</style>
