<template>
  <div id="container" class="svg-container" style="padding-bottom: 50%">
    <svg :id="'halfdonutskill' + id"></svg>
  </div>
</template>

<script>
import * as d3 from "d3";

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      required: true,
      default: null,
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },

  data() {
    return {
      chart: null,
    };
  },

  computed: {},

  mounted() {
    this.plotChart();
  },

  methods: {
    plotChart() {
      const anglesRange = 0.5 * Math.PI;
      const radius = Math.min(this.width, 2 * this.height) / 2;

      const data = [this.data.value / 100, 1 - this.data.value / 100];
      const data_media = [
        this.data.value_media / 100,
        1 - this.data.value_media / 100,
      ];
      const colors = [this.data.color, "#333333"];
      const colors_media = [this.data.color_media, "#333333"];

      const pie = d3
        .pie()
        .sort(null)
        .startAngle(anglesRange * -1)
        .endAngle(anglesRange);

      const arc = d3
        .arc()
        .outerRadius(radius - 10)
        .innerRadius(radius * 0.7);

      const arcMedia = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(radius * 0.95);

      /*   const arc = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(radius - 40)
        .padAngle(0.05); */

      /*     const mediaArc = d3
        .arc()
        .outerRadius(radius + 20)
        .innerRadius(radius + 10)
        .padAngle(0.05); */

      /*    const pie = d3
        .pie()
        .sort(null)
        .startAngle(1.1 * Math.PI)
        .endAngle(3.1 * Math.PI)
    /*    .value(function (d) {
          return d.value;
        }) ; */

      /*      const mediaPie = d3
        .pie()
        .sort(null)
        .startAngle(1.1 * Math.PI)
        .endAngle(3.1 * Math.PI)
        .value(function (d) {
          return d.value_media;
        }); */

      const svg = d3
        .select("#halfdonutskill" + this.id)
        .attr("preserveAspectRatio", "xMinYMin meet")
        .attr("viewBox", `0 0 ${this.width} ${this.height}`)
        .classed("svg-content", true);

      this.chart = svg
        .append("g")
        .attr(
          "transform",
          "translate(" + this.width / 2 + "," + this.height / 2 + ")"
        );

      this.chart
        .selectAll(".halfpie")
        .data(pie(data))
        .enter()
        .append("path")
        .attr("class", "halfpie")
        .attr("fill", function (d, i) {
          return colors[i];
        })
        .attr("d", arc);

      this.chart
        .selectAll(".halfpiemedia")
        .data(pie(data_media))
        .enter()
        .append("path")
        .attr("class", "halfpiemedia")
        .attr("fill", function (d, i) {
          return colors_media[i];
        })
        .attr("d", arcMedia);

      this.chart
        .append("text")
        .attr("fill", "#FFFFFF")
        .text(this.data.label)
        .attr("dy", "0")
        .attr("class", "label")
        .attr("font-size", "1.5rem")
        .attr("font-weight", "bold")
        .attr("text-anchor", "middle");

      this.chart
        .append("text")
        .attr("fill", "#FFFFFF")
        .text(this.data.label_media)
        .attr("dy", "-2rem")
        .attr("class", "labelMedia")
        .attr("font-size", "1rem")
        .attr("text-anchor", "middle");
      /*      .transition()
        .delay(function (d, i) {
          return i * 500;
        })
        .duration(500)
        .attrTween("d", function (d) {
          var i = d3.interpolate(d.startAngle, d.endAngle);
          return function (t) {
            d.endAngle = i(t);
            return arc(d);
          };
        }); */

      /*     this.chart
        .selectAll(".pieMedia")
        .data(mediaPie(this.games_data))
        .enter()
        .append("path")
        .attr("class", "pieMedia")
        .attr("fill", function (d) {
          return d.data.color_media;
        })
        .transition()
        .delay(function (d, i) {
          return i * 500;
        })
        .duration(500)
        .attrTween("d", function (d) {
          var i = d3.interpolate(d.startAngle, d.endAngle);
          return function (t) {
            d.endAngle = i(t);
            return mediaArc(d);
          };
        });

      this.chart
        .selectAll(".labelPie")
        .data(pie(this.games_data))
        .enter()
        .append("text")
        .attr("class", "labelPie")
        .attr("transform", function (d) {
          return "translate(" + arc.centroid(d) + ")";
        })
        .attr("dy", ".35em")
        .style("text-anchor", "middle")
        .style("font-size", 15)
        .style("font-weight", "bold")
        .attr("fill", "#000000")
        .transition()
        .delay(1000)
        .text(function (d) {
          return d.data.value > 0 ? d.data.label : "";
        });

      this.chart
        .selectAll(".labelMediaPie")
        .data(mediaPie(this.games_data))
        .enter()
        .append("text")
        .attr("class", "labelMediaPie")
        .attr("transform", function (d) {
          return "translate(" + mediaArc.centroid(d) + ")";
        })
        .attr("dy", ".35em")
        .style("text-anchor", "middle")
        .style("font-size", 8)
        .style("font-weight", "bold")
        .attr("fill", "#000000")
        .transition()
        .delay(1000)
        .text(function (d) {
          return d.data.value_media > 0 ? d.data.label : "";
        }); */
    },
  },
};
</script>
<style></style>
